import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { createElement } from "react";

import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";

import clsx from "clsx";

import {
  PreventFlashOnWrongTheme,
  Theme,
  ThemeProvider,
  useTheme,
} from "remix-themes";

import styles from "~/styles/globals.css?url";

import {
  themeSessionResolver,
  getLanguageFromSession,
  updateLanguageSession,
} from "./lib/sessions.server";

import { getTerms } from "./lib/services/term.server";
import { getMenus } from "./lib/services/menu.server";
import { getPage } from "./lib/services/page.server";

import { getBrowserEnvironment } from "./lib/utils";

import {
  Footer,
  Header,
  GTAG,
  ContactWidget,
  Toaster,
  NewsletterWidget,
  Button,
} from "./components";

type LoaderData = {
  env: ReturnType<typeof getBrowserEnvironment>;
  mode: Theme | null;
  menus: Awaited<ReturnType<typeof getMenus>>;
  lang: string;
  rootTerms: any;
  contactData: any;
};

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export async function loader({ request, params }: LoaderFunctionArgs) {
  // env
  const env = getBrowserEnvironment();

  // Theme
  const { getTheme } = await themeSessionResolver(request);
  const mode = getTheme();

  // Language
  const supportedLangs = ["en", "de"];
  let { lang } = params || (await getLanguageFromSession(request));
  if (!lang || !supportedLangs.includes(lang)) {
    lang = "de";
  }

  try {
    // Load terms
    const rootTerms = await getTerms(request, lang);

    // Get menus
    const menus = await getMenus(request, lang);

    // Get contact data
    const contactData = await getPage(request, "contact", lang);

    return json<LoaderData>(
      {
        env,
        mode,
        menus,
        lang,
        rootTerms,
        contactData,
      },
      { headers: await updateLanguageSession(request, lang) }
    );
  } catch (error) {
    throw new Response(
      "Our services are currently unavailable at the moment, please try again later",
      {
        status: 503,
      }
    );
  }
}

export function App() {
  const { env, mode, menus, lang, contactData } = useLoaderData() as LoaderData;

  const [theme] = useTheme();

  return (
    <html lang={lang} className={clsx(theme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(mode)} />
        <Links />
      </head>
      <body>
        <Header lang={lang} menus={menus} />
        <main>
          <Outlet />
        </main>
        <NewsletterWidget />
        <ContactWidget contactData={contactData} />
        <Footer lang={lang} menus={menus} />
        <Toaster />
        <GTAG gtag={env.GTAG} />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function AppWithProviders() {
  const { mode } = useLoaderData() as LoaderData;
  return (
    <ThemeProvider specifiedTheme={mode} themeAction="/action/set-theme">
      <App />
    </ThemeProvider>
  );
}

const isClient = typeof document !== "undefined";

export function ErrorBoundary() {
  const error: any = useRouteError();

  // capture with sentry
  captureRemixErrorBoundaryError(error);

  let errorData;
  if (error.status === 405 && error.data && JSON.parse(error.data)) {
    errorData = JSON.parse(error.data);
  }

  // Prevent showing error page on client error
  if (isClient) {
    return createElement("html", {
      suppressHydrationWarning: true,
      // Inject the server-side rendered HTML.
      dangerouslySetInnerHTML: {
        __html: document.getElementsByTagName("html")[0].innerHTML,
      },
    });
  }

  // Server-side rendered error page.
  return (
    <html lang="en" suppressHydrationWarning>
      <head>
        <title>Oops!</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
        <meta name="bingbot" content="noindex, nofollow" />
        <Meta />
        <Links />
      </head>
      <body
        className="min-h-screen w-screen flex flex-col justify-center items-center bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: "url(/images/errorbg.webp)" }}
      >
        <div className="page flex flex-col justify-center items-center">
          <img src="/images/broken-link-logo.svg" alt="404" />
          <h1>Oops!</h1>
          {isRouteErrorResponse(error) &&
            error.status !== 404 &&
            error.status !== 405 && (
              <>
                <p className="lead text-muted mb-10 w-1/2 text-center">
                  {
                    "Unfortunately, something has gone wrong. We're sorry and we'll take care of fixing the problem."
                  }
                </p>
              </>
            )}
          {isRouteErrorResponse(error) && error.status === 404 && (
            <>
              <p className="lead text-muted mb-10 w-1/2 text-center">
                {isRouteErrorResponse(error) &&
                  `${error.statusText}: ${error.data}`}
              </p>
              <a href="/">
                <Button className="btn btn-primary">Go Home</Button>
              </a>
            </>
          )}
          {isRouteErrorResponse(error) && error.status === 405 && (
            <>
              <p className="lead text-muted mb-10 w-1/2 text-center">
                {isRouteErrorResponse(error) &&
                  `${error.statusText}: ${errorData?.message}`}
              </p>
              <p>Try reading in supported language ?</p>
              <ul className="flex items-center space-x-4">
                <li>
                  <a href={errorData.url.en}>
                    <Button className="btn btn-primary">EN</Button>
                  </a>
                </li>
                <li>
                  <a href={errorData.url.de}>
                    <Button className="btn btn-primary">DE</Button>
                  </a>
                </li>
              </ul>
            </>
          )}
        </div>
      </body>
    </html>
  );
}

export default withSentry(AppWithProviders);
